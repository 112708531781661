import get from "lodash/get";

const beforeRoutEntereGuard = (to, from, next) => {
  next((vm) => {
    if (vm.$store.state.connections.current === null) {
      if (to.name.startsWith("Role") && to.params.id) {
        const roleId = to.params.id;
        vm.$store
          .dispatch("connections/fetchByRoleId", roleId)
          .then(({ connection, role }) => {
            vm.$store.commit("setRole", role);
            vm.$store.commit("connections/setCurrent", connection);
          })
          .catch((res) => console.debug(res));
      } else if (to.name.startsWith("UserMarket") && to.params.familyId) {
        const familyId = to.params.familyId;
        vm.$store
          .dispatch("connections/fetchByFamilyId", familyId)
          .then(({ connection, role }) => {
            vm.$store.commit("setRole", role);
            vm.$store.commit("connections/setCurrent", connection);
          })
          .catch((res) => console.debug(res));
      } else {
        const user = vm.$auth.user();
        vm.$store
          .dispatch("connections/fetchById", user.default_connection)
          .then(({ connection, role }) => {
            vm.$store.commit("setRole", role);
            vm.$store.commit("connections/setCurrent", connection);
          })
          .catch((res) => console.debug(res));
      }
    } else {
      if (to.name.startsWith("Role") && to.params.id) {
        const connectionRoleId = get(
          vm.$store.state.connections.current,
          "family.role.id"
        );

        const storeRoleId = get(vm.$store.state.role, "id");
        if (connectionRoleId !== storeRoleId) {
          const roleId = to.params.id;
          vm.$store
            .dispath("connections/fetchByRoleId", roleId)
            .then(({ connection, role }) => {
              vm.$store.commit("setRole", role);
              vm.$store.commit("connections/setCurrent", connection);
            })
            .catch((res) => console.debug(res));
        }
      }
    }
  });
};

export { beforeRoutEntereGuard };

<template>
  <router-view v-if="connections.current"></router-view>
</template>

<script>
import { mapState } from "vuex";
import { beforeRoutEntereGuard } from "../helpers/guards";

export default {
  name: "UiOrdersContainer",

  beforeRouteEnter(to, from, next) {
    beforeRoutEntereGuard(to, from, next);
  },

  computed: {
    ...mapState(["connections"]),
  },
};
</script>
